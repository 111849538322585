/* eslint-disable no-underscore-dangle */
import {combineReducers} from "redux";
import kycQuestionnaire from "@atg-aml-shared/kyc-domain/src/reducer/kycQuestionnaireReducer";
import {modals, modalData} from "atg-modals/modalReducer";
import {activeBreakpoint} from "atg-match-media/domain/matchMediaReducer";

const createRootReducer = (asyncReducers: any = {}) =>
    combineReducers<any, any>({
        // reducers that are lazily injected later will end up here
        ...asyncReducers.root,
        activeBreakpoint,
        alert: () => window._frameStore.getState().alert,
        auth: () => window._frameStore.getState().auth,
        accessToken: () => window._frameStore.getState().accessToken,
        router: () => window._frameStore.getState().router,
        user: () => window._frameStore.getState().user,
        modals,
        modalData,
        kycQuestionnaire,
        // inboxMessages-reducer lazy injects here when entering the mina-meddelanden page
    });

export default createRootReducer;
